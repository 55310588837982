import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"sm-padding": "60px 0 60px 0",
	"padding": "65px 0 65px 0",
	"quarkly-title": "Images-9"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"sm-margin": "0px 0px 30px 0px",
			"flex-direction": "column",
			"display": "flex",
			"width": "25%",
			"lg-width": "100%",
			"margin": "0px 0px 0px 0px",
			"padding": "16px 16px 16px 16px",
			"sm-padding": "0px 0px 0px 0px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"position": "relative",
			"transition": "transform 0.2s ease-in-out 0s",
			"height": "auto",
			"overflow-x": "hidden",
			"overflow-y": "hidden",
			"transform": "translateY(0px)",
			"hover-transform": "translateY(-10px)",
			"padding": "0px 0px 150% 0px"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"left": 0,
			"min-height": "100%",
			"top": 0,
			"right": 0,
			"bottom": 0,
			"src": "https://fesulkin.com/img/images.jpeg",
			"object-fit": "cover",
			"position": "absolute",
			"display": "block",
			"width": "100%",
			"object-position": "15%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "25%",
			"lg-width": "100%",
			"margin": "0px 0px 0px 0px",
			"padding": "16px 16px 16px 16px",
			"sm-padding": "0px 0px 0px 0px",
			"sm-margin": "0px 0px 30px 0px",
			"flex-direction": "column"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"height": "auto",
			"overflow-y": "hidden",
			"transition": "transform 0.2s ease-in-out 0s",
			"hover-transform": "translateY(-10px)",
			"padding": "0px 0px 150% 0px",
			"overflow-x": "hidden",
			"position": "relative",
			"transform": "translateY(0px)"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"bottom": 0,
			"min-height": "100%",
			"src": "https://fesulkin.com/img/a026.jpg",
			"object-fit": "cover",
			"width": "100%",
			"top": 0,
			"position": "absolute",
			"display": "block",
			"left": 0,
			"right": 0,
			"object-position": "0%"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"lg-align-items": "center",
			"padding": "16px 16px 16px 16px",
			"sm-margin": "0px 0px 0px 0px",
			"sm-padding": "0px 0px 0px 0px",
			"display": "flex",
			"width": "25%",
			"flex-direction": "column",
			"align-items": "flex-start",
			"lg-width": "100%",
			"lg-margin": "0px 0px 0px 0px",
			"lg-order": "1"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"overflow-x": "hidden",
			"transform": "translateY(0px)",
			"hover-transform": "translateY(-10px)",
			"padding": "0px 0px 150% 0px",
			"height": "auto",
			"overflow-y": "hidden",
			"position": "relative",
			"transition": "transform 0.2s ease-in-out 0s"
		}
	},
	"image2": {
		"kind": "Image",
		"props": {
			"left": 0,
			"right": 0,
			"bottom": "0px",
			"min-height": "100%",
			"object-fit": "cover",
			"position": "absolute",
			"display": "block",
			"top": "auto",
			"src": "https://fesulkin.com/img/dd364yhtrwg3433.jpg",
			"width": "100%"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"lg-align-items": "center",
			"padding": "16px 16px 16px 16px",
			"sm-margin": "0px 0px 0px 0px",
			"sm-padding": "0px 0px 0px 0px",
			"display": "flex",
			"width": "25%",
			"flex-direction": "column",
			"align-items": "flex-start",
			"lg-width": "100%",
			"lg-margin": "0px 0px 0px 0px",
			"lg-order": "1"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"overflow-x": "hidden",
			"transform": "translateY(0px)",
			"hover-transform": "translateY(-10px)",
			"padding": "0px 0px 150% 0px",
			"height": "auto",
			"overflow-y": "hidden",
			"position": "relative",
			"transition": "transform 0.2s ease-in-out 0s"
		}
	},
	"image3": {
		"kind": "Image",
		"props": {
			"left": 0,
			"right": 0,
			"bottom": "0px",
			"min-height": "100%",
			"object-fit": "cover",
			"position": "absolute",
			"display": "block",
			"top": "auto",
			"src": "https://fesulkin.com/img/dsgsdgsdg.jpg",
			"width": "100%"
		}
	}
};

const Images = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Image {...override("image")} />
			</Box>
		</Box>
		<Box {...override("box2")}>
			<Box {...override("box3")}>
				<Image {...override("image1")} />
			</Box>
		</Box>
		<Box {...override("box4")}>
			<Box {...override("box5")}>
				<Image {...override("image2")} />
			</Box>
		</Box>
		<Box {...override("box6")}>
			<Box {...override("box7")}>
				<Image {...override("image3")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Images, { ...Section,
	defaultProps,
	overrides
});
export default Images;